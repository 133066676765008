import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// REACT 18.0
// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html
//
// const container = document.getElementById('app');
// if (container === null) throw new Error('Root container missing in index.html');
//
// let root = createRoot(container);
//
// root.render(
//     <React.StrictMode>
//       <App tab="home" />
//     </React.StrictMode>,
// );
