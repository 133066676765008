import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import { ORGANIC_PROJECTS } from "../../Constants";
import { BLOCKCHAIN_PROJECTS } from "../../Constants";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-header">
          <strong>Recent Work</strong>
        </h1>
        <p style={{ color: "grey" }} className="blockquote">
          I am especially proud to be showcasing these projects. <br/>
          Please have a look around and let me know if you have any suggestions for improvement!
        </p><br/><br/>

        <div className="project-heading">
        <h3><strong>Independent, Non-Profit (Volunteer), & Proprietary Projects</strong></h3>
        </div>
          <Row className="projects-row">
            {ORGANIC_PROJECTS.map((project, index) => (
              <Col md={4} className="project-card" key={index}>
                <ProjectCard
                  imgPath={project.image}
                  technologyUsed={project.technologyUsed}
                  isBlog={false}
                  title={project.name}
                  description={project.description}
                  link={project.url}
                />
              </Col>
            ))}
          </Row>

        <br/><br/>
        
        <div className="project-heading">
        <h3><strong>Artificial Intelligence, Blockchain, & Just-For-Fun Projects</strong></h3>
        </div>
          <Row className="projects-row">
            {BLOCKCHAIN_PROJECTS.map((project, index) => (
              <Col md={4} className="project-card" key={index}>
                <ProjectCard
                  imgPath={project.image}
                  technologyUsed={project.technologyUsed}
                  isBlog={false}
                  title={project.name}
                  description={project.description}
                  link={project.url}
                />
              </Col>
            ))}
          </Row>

            <br/><br/>
      </Container>
    </Container>
  );
}

export default Projects;
