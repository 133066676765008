import React from "react";

import unicornLeft from "../../assets/skills/unicorn.png";
import jsLogo from "../../assets/skills/js-logo.png";
import reactLogo from "../../assets/skills/react-logo.png";
import reduxLogo from "../../assets/skills/redux-logo.png";
import html5Logo from "../../assets/skills/html5-logo.png";
import css3Logo from "../../assets/skills/css3-logo.png";
// import vueLogo from "../../assets/skills/vue-logo.png";
import pythonLogo from "../../assets/skills/python-logo.png";
import jupyterLogo from "../../assets/skills/jupyter-logo.png";
import colabLogo from "../../assets/skills/google-colab-logo.png";
import nvidiaLogo from "../../assets/skills/nvidia-logo.png";
// import solidity from "../../assets/skills/solidity-logo.png";
// import ganache from "../../assets/skills/ganache-logo.png";
// import truffle from "../../assets/skills/truffle-logo.png";
import nodejs from "../../assets/skills/nodejs-logo.png";
import unicornRight from "../../assets/skills/unicorn-right.png";
import websiteLogo from "../../assets/skills/logo.png";

const MarqueeComponent = () => (
  <>
    <img src={unicornLeft} width={75} height={75} alt="unicorn-face-left" />

    <img src={html5Logo} width={75} height={75} alt="html5 logo" />

    <img src={css3Logo} width={75} height={75} alt="css3 logo" />

    <img src={jsLogo} width={75} height={75} alt="javascript logo" />

    <img src={reactLogo} width={75} height={75} alt="react logo" />

    <img src={reduxLogo} width={75} height={75} alt="redux logo" />

    {/* <img src={vueLogo} width={75} height={75} alt="vue logo" /> */}

    <img src={nodejs} width={100} height={65} alt="nodejs logo" />

    <img src={pythonLogo} width={75} height={75} alt="python logo" />

    <img src={jupyterLogo} width={75} height={75} alt="Jupyter Notebook logo" />

    <img src={colabLogo} width={100} height={65} alt="google colab logo" />

    <img src={nvidiaLogo} width={100} height={65} alt="NVIDIA logo" />

    {/* <img src={solidity} width={75} height={75} alt="solidity logo" />

    <img src={ganache} width={75} height={75} alt="ganache logo" />

    <img src={truffle} width={75} height={75} alt="truffle logo" /> */} 

    <img src={unicornRight} width={75} height={75} alt="unicorn-face-right" />

    <img src={websiteLogo} width={125} height={125} alt="website logo" />
  </>
);

export default MarqueeComponent;
