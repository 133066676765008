import React from "react";
import { Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Experience from "../Resume/Experience";

import pdf from "../../assets/EPM-Resume-2023.pdf";
import { AiOutlineDownload } from "react-icons/ai";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function ResumeNew() {
  // const [width, setWidth] = useState(1200);

  // useEffect(() => {
  //   setWidth(window.innerWidth);
  // }, []);

  return (
    <div>
      <Container fluid className="resume-section">
        <span style={{ paddingTop: 25 }}>
          <center>
            <h1 className="project-header">Experience & Education</h1>
            {/* <center><p className="blockquote">Scroll to view the details</p></center><br/> */}
          </center>

          <span>
            <center>
              <div className="separator">
                <div className="line"></div>
                <h1>Download My Resumé (2 pages)</h1>
                <div className="line"></div>
              </div>
              <p className="blockquote">Please take care of this downloadable asset.</p>
            </center>

            <br/>
            <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
              <Button
                href={pdf}
                target="_blank"
                className="download-button"
                rel="noopener noreferrer"
              >
                <AiOutlineDownload size={20}/>
                {/* &nbsp;&nbsp; */}
                {"  "} Download file from a New Window
              </Button>
            </Row>
            <br />
            <br />
          </span>

        </span>
          <Experience/>
        <br />
        <br />

        {/*
        <Row className="resume">
          <center>
            <Col md={4}>
              <Document file={pdf}>
              <Page pageNumber={1} scale={width > 786 ? 1.3 : 0.6} />
              </Document>

              <Document file={pdf}>
              <Page pageNumber={2} scale={width > 786 ? 1.3 : 0.6} />
            </Document>
            </Col>
          </center>
        </Row>
        */}

        <br />
        <br />
      </Container>
    </div>
  );
}

export default ResumeNew;
