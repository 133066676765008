import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function Preloader(props) {
  const style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh", // This makes sure it takes the full viewport height
    // border: "1px solid red", // Sherlock Holmes style!
  }

  return (
    <div id={props.load ? "preloader" : "preloader-none"} style={style} >
      <Loader 
        type="Bars" 
        color="#096A9E" 
        height={80}
        width={80}
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        visible={true} />
    </div>
  );
}

export default Preloader;
