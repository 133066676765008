import React from "react";
import { Container, Row, Col } from "react-bootstrap";

// custom imports (components and files)
import myImage from "../../assets/emilypmendez.jpg";
// import ContactForm from "./ContactForm";
import SendMessageForm from "./SendMessageForm";
import TypeWriter from "./TypeWriter";
import Marquee from "react-fast-marquee";
import MarqueeComponent from "./MarqueeComponent";

function Home() {

  return (
    <>
      
      <Container className="home-header-section">
`       
        <Container fluid className="home-header-content">

          <Row className="home-header justify-content-center" >
            <Col xs={12} md={20} className="text-center">
              <Marquee
              loop={10}
              speed={85}
              delay={2.5}
              pauseOnHover={true}
              pauseOnClick={true}
              direction={"right"}
            >
              <Row className="marquee-container">
                <MarqueeComponent />
              </Row>
            </Marquee>
              <br/>
            </Col> 
          </Row>
        </Container>
        
        <Container>
          <Row>
            <Col md={5}>
              <img src={myImage} className="profile-pic" alt="avatar" />
            </Col>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 3 }} className="heading">
                Hi!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
                <span> You've reached</span>
              </h1>

              <h1 className="heading-name">
                <strong className="main-name"> Emily Portalatín-Méndez</strong>
              </h1>

              <p className="heading-description blockquote">
                <em>
                  She's quietly confident, naturally curious, and consistently
                  working on improving her coding skills.{" "}
                </em>
                <br />
              </p>

              <br />
              <p className="heading-additional-text blockquote">
                My journey as a software developer started at a young age, where I discovered
                the internet and all its possibilities. Since then, I've done remote work for agencies, consulted for startups, and
                collaborated with talented people to create digital products for
                business, consumer, and general use. <br /> <br />
                I am always looking to contribute to projects with significant
                and positive social impact. I am passionate about using Javascript and
                libraries for artificial intelligence to create awesome user experiences.
                You can typically find me reading, listening to music, or perusing the
                internet! Leave a message below to begin exploring opportunities to work together.
              </p>
            </Col>
          </Row>
        </Container>

      </Container>

        <Container>
          <Row>
            <Col md={12} className="home-about-social blockquote">
            <TypeWriter />              
              <div className="title-heading"><h1>Let's Get Connected!</h1></div>
              <br />
              <p>
                Award-winning developer with 8 years of well-rounded
                experience in project management, software development with
                object-oriented programming, and user-centered design. Seeking a remote
                position with a top technology firm.
              </p> 
              <br />
              <p>
                {" "} 
                Whether you have a question or just want to say hi, 
                I'll do my best to get back to you in a timely manner.
                <br /><br/>
              </p>

              <SendMessageForm />
              <br/>
            </Col>
          </Row>
        </Container>

      </>
  );
}

export default Home;
