import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SocialMedia from "./SocialMedia";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();

  return (
    <Container fluid className="footer">
      <Row className="footer-row">
        <Col sm="4" className="footer-copyright">
          <center><h3>Developed with ♥ and ☕ by yours truly</h3></center>
        </Col>
        <Col sm="4" className="footer-copyright">
          <center><h3>Copyright © {year} </h3></center>
        </Col>
        <Col sm="4" className="footer-body">
          <center><SocialMedia /></center>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
