import React from "react";
import Typewriter from "typewriter-effect";

function TypeWriter() {
  const style = {
    marginTop: '5%',
    marginBottom: '5%',
  }
  return (
    <div style={style}>
      <Typewriter
        options={{
          strings: [
            "Integrity. Innovation. Impact. 🌎",
            "AI & Blockchain Enthusiast 🤖",
            "Self-Taught Developer 💅",
            "Aspiring Barista ☕ 🙃 👌",
            "Music Lover 🎶🎵🎶"
          ],
          autoStart: true,
          loop: true,
          deleteSpeed: 50
        }}
      />
    </div>
  );
}

export default TypeWriter;
