import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";

export default function AllPosts(props) {
  const [allPostsData, setAllPosts] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
        title,
        slug,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => {
        console.log(data); // check to see if data is coming through
        setAllPosts(data)
        })
      .catch(console.error);
  }, []);

  return (
    <div className="min-h-screen p-12">
      <div className="container mx-auto">
      <br/><br/><br/>
        <h2 className="text-3.5xl flex justify-center"><strong>Welcome to my blog!</strong></h2><br/>
        <h3 className="text-lg text-gray-600 flex justify-center mb-12">
          <em>Enjoy articles about programming, design science, and my general musings.</em>
        </h3><br/>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">

          {allPostsData &&
            allPostsData.map((post, index) => (
              <Link to={"/" + post.slug.current} key={post.slug.current}>
                <span
                  className="block h-64 relative rounded shadow leading-snug bg-white border-l-8 border-blue-400"
                  key={index}
                >
                    {/* CUSTOM CHECK FOR IMAGES */}
                    {/* Check if mainImage and asset exist before accessing url */}
                    {post.mainImage && post.mainImage.asset && (
                    <img
                        className="w-full h-full rounded-r object-cover absolute"
                        src={post.mainImage.asset.url}
                        alt={post.title} // providing a meaningful alt text
                    />
                    )}

                    {/* <img
                        className="w-full h-full rounded-r object-cover absolute"
                        src={post.mainImage.asset.url}
                        alt=""
                    /> */}

                  <span className="block relative h-full flex justify-end items-end pr-4 pb-4">
                    <h2 className="text-gray-800 text-lg font-bold px-3 py-4 bg-blue-700 text-red-100 bg-opacity-75 rounded">
                      {post.title}
                    </h2>
                  </span>
                </span>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
}
